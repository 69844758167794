import React, {useEffect, useState} from 'react';
import {pathToLogoDirectory, secondsToTime} from '../../utils/helpers';
import {Card} from 'react-bootstrap';
import './CustomerCard.scss';
import persistentStore from '../../stores/persistentStore';
import {stripnamePlural} from '../../utils/stripname';
import {useTranslation} from 'react-i18next';
import {ICustomer} from "../../interfaces/ICustomer";
import {IUser} from "../../interfaces/IUser";
import {useHistory} from "../../utils/router";
import {ChevronsRight} from "react-feather";
import {observer} from "mobx-react";

type CustomerCardMobileProps = {
    className?: string,
    user?: IUser,
    customer: ICustomer,
    selectedCustomers: string[],
    addCustomer: (publicId: string) => void,
    removeCustomer: (publicId: string) => void,
    groupClicked: boolean,
}

const CustomerCardMobile = (props: CustomerCardMobileProps) => {
    const {t} = useTranslation();
    const {className, customer, selectedCustomers, addCustomer, removeCustomer} = props
    const isSelected: boolean = selectedCustomers.includes(customer.public_id)
    const stripsBought = customer.stats.total_amount_on_active_cards;
    const history = useHistory()
    const [percentageUsed, setPercentageUsed] = useState(0)
    const usesTime = ['time', 'hours', 'uur'].includes(persistentStore.user?.settings.useType || 'strippen')

    const tegoedMessage = () => {
        // uses time or strips?
        // get the appropriate message
        if (usesTime) {
            const timeLeft = secondsToTime(customer.stats.total_time_in_seconds_available);
            return `${timeLeft} over`;
        }
        else {
            // show time instead of strips
            return `${customer.stats.total_strips_available} ${stripnamePlural()} tegoed.`;
        }
    };

    const totalStrips = customer.stats.total_amount_on_active_cards
    const totalUsedStrips = customer.stats.total_strips_used

    useEffect(() => {
        // when a customer has 0 cards, the percentage used is 0%
        if (totalStrips === 0) {
            setPercentageUsed(0)
            return
        }

        if(usesTime){
            if(customer.stats.total_time_in_seconds_available < 1) {
                setPercentageUsed(100)
                return
            }
        }
        else {
            if(customer.stats.total_strips_available < 1) {
                setPercentageUsed(100)
                return
            }
        }

        setPercentageUsed(parseInt(String(customer.stats.percentage_used)))

    }, [customer.stats.total_strips_available, customer.stats.total_time_in_seconds_available, customer.stats.total_time_in_seconds_on_active_cards, customer.stats.percentage_used, totalStrips, totalUsedStrips, usesTime])

    let backgroundColor;

    switch (true) {
        case percentageUsed < 50:
            backgroundColor = 'green';
            break;
        case percentageUsed < 75:
            backgroundColor = '#FA29F9';
            break;
        case percentageUsed < 95:
            backgroundColor = '#ED0255';
            break;
        default:
            backgroundColor = 'red';
            break;
    }

    // the text to show in the progressbar
    let barText = percentageUsed + '% gebruikt';

    const progressStyle = {
        width: percentageUsed + '%',
        // "background-color": backgroundColor,
        'backgroundColor': backgroundColor,
    }

    const toggleSelectCustomer = (customerToToggle: ICustomer) => {
        const customerIsSelected = selectedCustomers.includes(customerToToggle.public_id)

        if (customerIsSelected) {
            removeCustomer(customerToToggle.public_id)
            return
        }

        addCustomer(customerToToggle.public_id)
    }

    const selectedStyle = {
        backgroundColor: 'rgb(233 253 226 / 1)',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    }

    // don't display this customer when only selected customers are to be displayed
    if (selectedCustomers.length > 0 && !isSelected && props.groupClicked) {
        return <></>
    }

    return (
        <Card
            className={className || ''}
            key={customer.id}
            onClick={() => toggleSelectCustomer(customer)}
            style={isSelected ? selectedStyle : {}}
        >
            <div className='customer-image'>
                {customer.logo && <img src={`${pathToLogoDirectory()}/${customer.logo}`} alt={customer.name} className='rounded-circle' />}
            </div>
            <div
                className="card-body d-flex"
                style={{flexDirection: 'row'}}
            >
                <div className="card-name pt-2 pl-2 pb-2 mt-3">
                    <h5 className='customer-name'>
                        {customer.name}

                        {customer.archived && (
                            <span className="badge badge-secondary ml-2"> {t('archive')} </span>
                        )}
                    </h5>
                    <div className="card-text"> {tegoedMessage()} </div>
                </div>

                <div
                    className="view-details d-flex pr-2 pl-2 col-2"
                    style={{marginLeft: 'auto', height: '100%', alignItems: 'center'}}
                    onClick={() => history.push('/customers/' + customer.public_id)}
                >
                    <ChevronsRight/>
                </div>

            </div>

            <div
                className="progress"
                style={{borderRadius: 0, borderBottomLeftRadius: '.25rem', borderBottomRightRadius: '.25rem'}}
            >
                <div className="progress-bar"
                     role="progressbar"
                     style={progressStyle}
                     aria-valuenow={percentageUsed}
                     aria-valuemin={0}
                     aria-valuemax={stripsBought}
                >
                    {barText}
                </div>
            </div>
        </Card>
    );
};

export default observer(CustomerCardMobile);
