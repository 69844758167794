import React from 'react';
import {Button, ButtonGroup, Col} from "react-bootstrap";
import {useRouter} from "../../utils/router";
import {useTranslation} from "react-i18next";
import ToolTip from "../../components/ToolTip";
import {pathToLogoDirectory} from "../../utils/helpers";

type CustomerHeaderProps = {
    customerQuery: any,
    setShowConfirm: (confirm: boolean) => void
}

const CustomerHeader = (props: CustomerHeaderProps) => {
    const {customerQuery, setShowConfirm} = props
    const router = useRouter()
    const {t} = useTranslation()

    return (
        <div className="col-12 customer-header" style={{padding: 0, marginLeft: '-1rem'}}>
            <div className='bg-image' style={{maxWidth: "100%", marginLeft: 0, marginRight: 0}}>
                <div className='bg-black-50'>
                    <div className='content content-full text-center pb-2'>
                        {customerQuery.data?.logo && (
                            <div className='my-3'>
                                <img
                                    className="img-avatar img-avatar-thumb"
                                    src={pathToLogoDirectory() + '/' + customerQuery.data?.logo} alt=""
                                />
                            </div>
                        )}
                        <h1 className={`h2 text-white mb-4 ${!customerQuery.data?.logo && 'pt-4'}`}>
                            {customerQuery.data?.name}
                            {customerQuery.data?.archived && (
                                <span className="badge badge-secondary ml-2">
                                {t('archived')}
                                </span>
                            )}
                        </h1>
                    </div>

                    <Col>
                        <Col sm={4} className="content content-full text-center customerview right">
                            <ButtonGroup>
                                <ToolTip text={t('customer view')}>
                                    <Button
                                        variant='warning'
                                        id='customer-view'
                                        onClick={() => router.push(`/status/${customerQuery.data?.public_id}`)}
                                        data-for='klantweergave'
                                        data-tip
                                    >
                                        <i className='fa fa-eye'/>
                                    </Button>
                                </ToolTip>
                                <ToolTip text={t('edit')}>
                                    <Button
                                        variant='info'
                                        id='customer-edit'
                                        onClick={() => router.push(`/customers/edit/${customerQuery.data?.public_id || 0}`)}
                                        data-for='edit'
                                        data-tip
                                    >
                                        <i className='fa fa-edit'/>
                                    </Button>
                                </ToolTip>
                                <ToolTip text={t('delete')}>
                                    <Button
                                        variant='danger'
                                        id="customer-delete"
                                        onClick={() => setShowConfirm(true)}
                                        data-for='delete'
                                        data-tip
                                    >
                                        <i className='fa fa-trash'/>
                                    </Button>
                                </ToolTip>
                            </ButtonGroup>
                        </Col>
                    </Col>
                </div>
            </div>
        </div>
    )
}

export default CustomerHeader;
