import Spinner from 'react-feather/dist/icons/settings';
import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {Row, Col, Card} from "react-bootstrap";
import {User as UserIcon} from 'react-feather';
import {ICustomer} from "../../interfaces/ICustomer";
import './CustomerRow.scss'
import {useRouter} from "../../utils/router";
import useTime from "../../utils/useTime";
import {secondsToTime} from "../../utils/helpers";

type Props = {
    customers: ICustomer[],
    loading: boolean,
    title: string
}

export const CustomerStripsBought = observer((props: Props) => {
    const {customers, loading, title} = props
    const [sortedCustomers, setSortedCustomers] = useState<ICustomer[]>([])

    useEffect(() => {
        if (!customers) {
            return
        }
        const customersOrderedByStripsBought = customers
            .filter((customer: ICustomer) => customer.deleted_at === null)
            .filter((customer: ICustomer) => customer.archived === false)
            .sort((a: ICustomer, b: ICustomer) => b.stats.total_time_in_seconds_on_active_cards - a.stats.total_time_in_seconds_on_active_cards)
            .slice(0, 5)

        setSortedCustomers(customersOrderedByStripsBought)
    }, [customers])

    return (
        <Card>
            <Card.Header as="h5">{title}</Card.Header>
            <Card.Body>
                {sortedCustomers.map((customer: ICustomer, index: number) => (
                    <CustomerRow customer={customer} key={index} />
                ))}
            </Card.Body>
            {loading && <h1 className="text-center"><Spinner className="spinner"/></h1>}
        </Card>
    )
})

const CustomerRow = (props: any) => {
    const {customer}: { customer: ICustomer } = props
    const router = useRouter()

    return (
        <Row className="border-bottom mb-2 pb-2 customer-row" onClick={() => router.push(`/customers/${customer.public_id}`)}>
            <Col md={8} className='pr-0'>
                <Row>
                    <Col md={3} className="pr-0 pl-0 text-center">
                        <UserIcon size={50}/>
                    </Col>
                    <Col md={9} className="pt-3">
                        <b>{customer.name}</b>
                    </Col>
                </Row>
            </Col>
            <Col md={4} className="pr-0 pt-3 text-right pr-3">
                {(useTime ? secondsToTime(customer.stats.total_time_in_seconds_on_active_cards) : customer.stats.total_amount_on_active_cards)}
            </Col>
        </Row>
    )
}

