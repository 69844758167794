import React from 'react';
import DataTable from "react-data-table-component";
import FilterComponent from "../../components/FilterComponent";
import persistentStore from "../../stores/persistentStore";
import {IEvent} from "../../interfaces/IEvent";
import {useQuery} from "react-query";
import moment from "moment/moment";
import {teams} from "../../api/teams";

const EventsTable = (props: any) => {
    const [filterText, setFilterText] = React.useState('');
    const {teamId}: {teamId: number} = props
    const events = useQuery(
        'eventRecords',
        () => teams.events(teamId),
        { enabled: teamId !== undefined }
    )

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return <FilterComponent
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
        />;
    }, [filterText]);

    const filteredEvents: IEvent[] = events.data?.events.filter((event: IEvent): boolean => {
        if(filterText === ''){
            return true
        }
        const data = JSON.stringify(event.data).toLowerCase()
        const query = filterText.toLowerCase()

        return Boolean(event.data && event.data.toLowerCase().includes(query))
            || Boolean(data && data.includes(query))
            || Boolean(event.type && event.type.toLowerCase().includes(query))
            || Boolean(event.email && event.email.toLowerCase().includes(query))
    });

    const columns = [
        {
            name: 'Created',
            selector: 'created_at',
            sortable: true,
            width: '200px',
            cell:  (event: IEvent) => {
                return moment(event.created_at).format('YYYY-MM-DD HH:mm')
            }
        },
        {
            name: 'Type',
            selector: 'type',
            sortable: true,
            width: '350px'
        },
        // {
        //     name: 'Name',
        //     selector: 'name',
        //     sortable: true,
        //     width: '200px'
        // },
        // {
        //     name: 'Email',
        //     selector: 'email',
        //     sortable: true,
        //     width: '200px'
        // },
        {
            name: 'Data',
            sortable: false,
            cell:  (event: IEvent) => {
                if(
                    (event.type === 'MAINTENANCE_REMOVE_CUSTOMER')
                    || (event.type === 'CARD_IS_RUNNING_LOW')
                    || (event.type === 'NOTIFY_CUSTOMER_BY_EMAIL')
                ){
                    return event.data[0]
                }

                if((event.type === 'USER_DELETED_CUSTOMER')){
                    return `${event.data['name']} (${event.data['email']})`
                }
            }
        },
    ]

    return (
        <DataTable
            columns={columns}
            data={filteredEvents}
            pagination
            paginationPerPage={persistentStore.pageLength}
            paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
            onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
            noHeader
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            progressPending={events.isLoading}
            defaultSortField='created'
            defaultSortAsc={false}
        />
    );
}

export default EventsTable;
