import React, {FormEvent, useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import store from '../../stores/store';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {useRouter} from '../../utils/router';
import {customers as customersApi, customers} from '../../api/customers';
import {toast} from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import {useDropzone} from 'react-dropzone';
import {Alert, Button, Row, Col, Image} from 'react-bootstrap';
import {Image as ImageIcon} from 'react-feather';
import persistentStore from '../../stores/persistentStore';
import {useQuery, useQueryCache} from 'react-query';
import * as Sentry from '@sentry/browser';
import ToggleSlider from "../../components/ToggleSlider";
import {ICustomer} from "../../interfaces/ICustomer";
import {pathToLogoDirectory} from "../../utils/helpers";

const CustomerEdit = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [addressingName, setAddressingName] = useState('');
    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');
    const [addressingNameError, setAddressingNameError] = useState('');
    const [notesError, setNotesError] = useState('');
    const [customerReferenceError, setCustomerReferenceError] = useState('')
    const [deleteLogo, setDeleteLogo] = useState(false);
    const {t} = useTranslation();
    const router = useRouter();
    const customerPublicId = router.query.customerPublicId;
    const [customer, setCustomer] = useState<ICustomer>();

    const queryCache = useQueryCache();
    const customerQuery = useQuery(
        'getCustomerByPublicId',
        () => customersApi.getByPublicId(customerPublicId)
    );

    const [userLogo, setUserLogo] = useState('');
    const [selectedFile, setSelectedFile] = useState('');
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedFileNamePreview, setSelectedFileNamePreview] = useState('');
    const [uploadError, setUploadError] = useState<string | undefined>();
    const [customerText, setCustomerText] = useState('');
    const [notes, setNotes] = useState('');
    const [customerReference, setCustomerReference] = useState('');
    const [customerTextError, setCustomerTextError] = useState('');
    const [isArchived, setIsArchived] = useState(false);
    const [isExemptFromTaxes, setIsExemptFromTaxes] = useState(false);
    const isUnMounted = useRef(false)

    useEffect(() => {
        return () => {
            isUnMounted.current = true
        }
    }, [])

    useEffect(() => {
        if (customerQuery.data) {
            setCustomer(customerQuery.data)
            setName(customerQuery.data.name);
            setEmail(customerQuery.data.email);
            setAddressingName(customerQuery.data.addressing_name || '')
            setUserLogo(customerQuery.data.logo || '');
            setCustomerText(customerQuery.data.customer_text || '');
            setNotes(customerQuery.data.notes || '');
            setCustomerReference(customerQuery.data.invoice_references || '')
            setIsArchived(customerQuery.data.archived);
            setIsExemptFromTaxes(customerQuery.data.exempt_from_taxes)
        }

        // on unmount, set loading to false
        return () => {setLoading(false)}
    }, [customerQuery.data]);

    // when the form is submitted
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        store.triggerEvent('customer_edited');

        if (deleteLogo) {
            deleteLogoFromServer();
        }

        const updatedCustomer: ICustomer = Object.assign(
            {},
            customer,
            {
                name: name,
                email: email,
                addressing_name: addressingName,
                customer_text: customerText,
                notes: notes,
                archived: isArchived,
                id: customerPublicId,
                invoice_references: customerReference,
                exempt_from_taxes: isExemptFromTaxes
            }
        )

        customers.update(updatedCustomer)
            .then(response => {
                setLoading(false)
                if (response.success === true) {

                    if (typeof selectedFile === 'object') {
                        uploadLogo();
                    }

                    toast.success(t('Customer updated'));
                    queryCache.invalidateQueries('getCustomer');
                    router.push(`/customers/${customerQuery.data.public_id}`);
                    return;
                }

                const errors = validationErrors(response.data.errors);

                Object.entries(errors as { [key: string]: string })
                    .forEach(([field, message]) => {
                        switch (field) {
                            case 'name':
                                setNameError(message);
                                break;
                            case 'customer_text':
                                setCustomerTextError(message);
                                break;
                            case 'notes':
                                setNotesError(message);
                                break;
                            case 'email':
                                setEmailError(message);
                                break;
                            case 'addressing_name':
                                setAddressingNameError(message);
                                break;
                            case 'reference':
                                setCustomerReferenceError(message);
                                break;
                            default:
                                console.log(`Error for unknown field: ${field}`);
                        }
                    });
            })
            .catch((error) => {
                setLoading(false)
                console.log(`error caught when updating customer: `, error);
                Sentry.captureException(error);
            })
    };

    const uploadLogo = () => {
        const formData = new FormData();
        formData.append(
            'logo',
            selectedFile,
            selectedFileName,
        );
        formData.append(
            'id',
            customerQuery.data.public_id,
        );

        customers
            .updateLogo(formData)
            .then((response) => {
                if (response.success) {
                    setSelectedFile('');
                    setUserLogo(response.data.customer.logo);
                    queryCache.invalidateQueries('getCustomer');
                }
            })
            .catch((error) => {
                toast.error(t('Could not update logo'));
                Sentry.captureException(error);
            });
    };

    /**
     * Returns an array with the field as key and the message as value
     * @param errors
     */
    const validationErrors = (errors: any) => {
        const validationErrorsObject: { [key: string]: string } = {}

        for (const [field, errorObject] of Object.entries(errors)) {
            // const message = Object.entries(errorObject as [string, string])
            //     .map( ([fieldName, message]: [string, string]) => message);
            // validationErrors[field] = message[0];
            console.log(errorObject)
            validationErrorsObject[field] = 'test'
        }

        return validationErrorsObject;
    };

    const onDrop = useCallback(acceptedFiles => {
        // clear any upload errors
        setUploadError(undefined);

        const fileToUpload = acceptedFiles[0];
        if (!fileToUpload) {
            return;
        }

        const sizeInMB = fileToUpload.size / 1000000;
        const fileTooLarge = sizeInMB > store.logoUploadLimit;

        if (fileTooLarge) {
            const errorMessage = t('This file is over the maximum file limit of')
                + ' ' + store.logoUploadLimit + 'MB '
                + `): ${sizeInMB.toFixed(2)}M. ` +
                t('Please select a smaller image and try again');
            // get the file size in standard format
            setUploadError(errorMessage);
            return;
        }

        setSelectedFileName(fileToUpload.name);
        setSelectedFile(fileToUpload);
        setSelectedFileNamePreview(URL.createObjectURL(fileToUpload));
        // eslint-disable-next-line
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png, image/gif, image/bmp',
    });

    const removeFile = () => {
        setSelectedFileName('');
        setSelectedFile('');
        setSelectedFileNamePreview('');
    };

    const clearLogoLocally = () => {
        setUserLogo('');
        setSelectedFileName('');
        setSelectedFile('');
        setSelectedFileNamePreview('');
        setDeleteLogo(true);
    };

    const deleteLogoFromServer = () => {
        customers
            .clearLogo(customerQuery.data.public_id)
            .then((response) => {
                if (response.success) {
                    persistentStore.updateCustomerFromServer(customerQuery.data.public_id);
                    queryCache.invalidateQueries('customer');
                    setSelectedFile('');
                    setUserLogo('');
                    queryCache.invalidateQueries('getCustomer');
                }
            })
            .catch((error) => {
                toast.error(t('Could not remove logo'));
                Sentry.captureException(error);
            });
    };

    return (
        <>
            <h1>{t('Klant wijzigen')}</h1>

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <h4>{t('Customer name')}</h4>
                    <label htmlFor="inputName">
                        {t('Name of the customer as it appears in the Strippenkaart App')}.&nbsp;
                        {t('You can use descriptive name here')}.
                    </label>
                    <input type="text"
                           className="form-control"
                           id="inputName"
                           aria-describedby="nameHelp"
                           onChange={(e) => setName(e.target.value)}
                           value={name}
                           placeholder={t('Name')}
                           name="name"
                           disabled={customerQuery.isLoading}
                    />
                    {nameError && (
                        <Alert variant="warning">{nameError}</Alert>
                    )}
                </div>

                <div className="form-group">
                    <h4>{t('Customer name in communication')}</h4>
                    <label htmlFor="inputAddressingName">{t('This name is used to address the customer in emails')}</label>
                    <input type="text"
                           className="form-control"
                           id="inputAddressingName"
                           aria-describedby="nameHelp"
                           onChange={(e) => setAddressingName(e.target.value)}
                           value={addressingName}
                           placeholder={t('Addressing Name')}
                           name="addressingName"
                           disabled={customerQuery.isLoading}
                    />
                    {addressingNameError && (
                        <Alert variant="warning">{addressingNameError}</Alert>
                    )}
                </div>

                <div className="form-group">
                    <h4>{t('E-mail adres')}</h4>
                    <label htmlFor="inputEmail1">{t('E-mailadres')}</label>

                    <input type="email"
                           className="form-control"
                           id="inputemail"
                           aria-describedby="emailhelp"
                           placeholder={t('email')}
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           name="email"
                           disabled={customerQuery.isLoading}
                    />
                    {emailError && (
                        <Alert variant="warning">{emailError}</Alert>
                    )}
                </div>

                <div className="form-group">
                    <h4>{t('Customer text')}</h4>
                    <label htmlFor="customerText">
                        {t('Add text your customer will see on its personal page')}
                    </label>
                    <textarea
                        className="form-control"
                        id="customerText"
                        name="customerText"
                        value={customerText}
                        onChange={(e) => setCustomerText(e.target.value)}
                        rows={4}
                    />
                    {customerTextError && (
                        <Alert variant="warning">{customerTextError}</Alert>
                    )}
                </div>

                <div className="form-group">
                    <h4>{t('Customer notes')}</h4>
                    <label htmlFor="customerNotes">
                        {t('Add notes for this customer')}.
                        {t('These are only visible to you and no one else on the customer page')}.
                    </label>
                    <textarea
                        className="form-control"
                        id="customerNotes"
                        name="customerNotes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        rows={4}
                    />
                    {notesError && (
                        <Alert variant="warning">{notesError}</Alert>
                    )}
                </div>

                <div className="form-group">
                    <h4>{t('Invoice Reference')}</h4>
                    <label htmlFor="invoiceReference">
                        {t('Add text that will be added to the customer information on the invoice')}.&nbsp;
                        {t('This is the place where you could add TAX-numbers, customer reference, etc')}.
                    </label>
                    <textarea
                        className="form-control"
                        id="customerReference"
                        name="customerReference"
                        value={customerReference}
                        onChange={(e) => setCustomerReference(e.target.value)}
                        rows={4}
                    />
                    {customerReferenceError && (
                        <Alert variant="warning">{customerReferenceError}</Alert>
                    )}
                </div>

                <div className="form-group h-100">
                    <h4>{t('Upload logo for your customer')}</h4>
                    <div className="mb-2">
                        {t('The logo will be used on the customer page')}
                    </div>
                    {uploadError && <Alert variant="danger">{uploadError}</Alert>}
                    <Row className="col-md-12">
                        <Col sm={2}>
                            {selectedFile && (
                                <>
                                    <Image
                                        thumbnail
                                        width={150}
                                        alt={selectedFileName}
                                        src={selectedFileNamePreview}
                                    />
                                    <span className="btn text-danger" onClick={removeFile}>
                                            {t('Remove')}
                                        </span>
                                </>
                            )}

                            {userLogo && !selectedFile && (
                                <>
                                    <Image
                                        thumbnail
                                        width={150}
                                        alt={persistentStore.user?.name}
                                        src={`${pathToLogoDirectory()}/${userLogo}`}
                                    />
                                    <span
                                        className="btn text-danger"
                                        onClick={clearLogoLocally}
                                    >
                        {t('Remove')}
                      </span>
                                </>
                            )}

                            {!userLogo && !selectedFile && (
                                <div
                                    className="h-100 justify-content-center align-items-center row"
                                    style={{backgroundColor: 'lightgray'}}
                                >
                                    <ImageIcon size={100} color={'gray'} className="col-12"/>
                                </div>
                            )}
                        </Col>

                        <Col>
                            <div className="form-control" {...getRootProps()}
                                 style={{minHeight: '150px'}}>
                                <input {...getInputProps()} />
                                {isDragActive ?
                                    <p>{t('Drop them here')}...</p> :
                                    <p>{t('Drag your logo here to upload them')}</p>
                                }
                            </div>
                            {isDragReject && (
                                <p>{t('This file extension is not allowed')}.</p>
                            )}
                        </Col>
                    </Row>
                </div>

                <div className="form-group">
                    <h4>{t('Taxes')}</h4>
                    <div className="mb-2">
                        {t('When the customer does not have to pay taxes, toggle this switch off')}.
                    </div>
                    <ToggleSlider
                        id='is-exempt-from-taxes'
                        size='lg'
                        type='round'
                        checked={isExemptFromTaxes}
                        onChange={(checked: boolean) => {
                            setIsExemptFromTaxes(checked)
                        }}
                        text={isExemptFromTaxes ? t('The customer is exempt of taxes') : t('The customer is liable to taxes')}
                    />
                </div>

                <div className="form-group">
                    <h4>{t('Archive')}</h4>
                    <div className="mb-2">
                        {t('Archived customers won\'t be displayed in the' +
                            ' customer listing')}.
                        <br/>
                        {t('Use this to clean up your customer overview by hiding inactive customers')}
                    </div>
                    <ToggleSlider
                        id='is-archived'
                        size='lg'
                        type='round'
                        checked={isArchived}
                        onChange={(checked: boolean) => {
                            setIsArchived(checked)
                        }}
                        text={isArchived ? t('customer is archived') : t('customer is active')}
                    />
                </div>

                <Button
                    type="submit"
                    id="btn-customer-save"
                    className="btn btn-primary"
                    disabled={customerQuery.isLoading}
                >
                    {(customerQuery.isLoading || loading) && (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                    )}
                    {(!customerQuery.isLoading && !loading) && t('Save')}
                </Button>
                &nbsp;
                <Link to={`/customers/${customerQuery.data?.public_id}`}>
                    <button className="btn btn-link">{t('Cancel')}</button>
                </Link>
            </form>
        </>
    );
};

export default observer(CustomerEdit);
