import React, {useEffect, useState} from 'react';
import {useLoading} from "../../utils/helpers";
import {ICardType} from "../../interfaces/ICardType";
import {useTranslation} from "react-i18next";
import persistentStore from "../../stores/persistentStore";
import {useMutation, useQuery, useQueryCache} from "react-query";
import {cardtypes} from "../../api/cardtypes";
import {toast} from "react-toastify";
import {Alert, Button, Card, Col, Row} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import CardType from "./CardType";
import AddCardTypeModal from "./AddCardTypeModal";
import CardTypeSettings from "./CardTypeSettings";
import EditCardTypeModal from "./EditCardTypeModal";
import OrderTable from "../Users/OrderTable";
import {orders} from "../../api/orders";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-regular-svg-icons/faCopy";
import {Loader} from "react-feather";
import Tooltip from "../../components/ToolTip";

const CardTypesPage = () => {
    const [loading, startLoading, stopLoading] = useLoading()
    const [selectedCard, setSelectedCard] = useState<ICardType | undefined>()
    const {t} = useTranslation()
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const saleSlug: string = persistentStore.user?.sale_slug || ''
    const queryCache = useQueryCache()
    const hasMollieLiveKey: boolean = persistentStore.user?.settings.mollieLiveKey !== ''
    const hasMollieTestKey: boolean = persistentStore.user?.settings.mollieTestKey !== ''
    const hasMollieSet: boolean = (hasMollieLiveKey || hasMollieTestKey)
    const salesPageUrl = `${window.location.origin}/order/${persistentStore.user?.sale_slug}`;

    const [deleteCardTypeMutation, {isError: isErrorDeletion, isSuccess: isSuccessDeletion}] = useMutation(
        (cardId: string) => cardtypes.del(cardId)
    )
    const cardtypesQuery = useQuery('cardtypes', () => cardtypes.getFromSaleSlug(saleSlug))
    const ordersQuery = useQuery(
        'getOrders',
        orders.getFromUser,
        {
            enabled: (persistentStore.user !== undefined)
        }
    )

    const clearQueryCache = () => {
        queryCache.invalidateQueries('cardtypes')
        queryCache.invalidateQueries('getOrders')
    }

    const addCardType = (event: any, card: ICardType) => {
        event.preventDefault()

        if (!persistentStore.user) {
            return
        }

        startLoading()

        const addCardType: ICardType = Object.assign(
            {
                user_id: persistentStore.user?.id,
                name: card.name,
                validity_time: card.validity_time,
                default_quantity: card.default_quantity,
                price_in_cents: card.price_in_cents,
                description: card.description,
            }
        )

        cardtypes.add(addCardType)
            .then(response => {
                setShowAddModal(false)
                resetAddForm()
                if (response.success) {
                    toast.success(t('Card types added'))
                } else {
                    toast.error(t('Card types could not be added'))
                }
            })
            .finally(() => {
                clearQueryCache()
                stopLoading()
            })
    }

    useEffect(() => {
        if (isSuccessDeletion) {
            clearQueryCache()
            setShowDeleteModal(false)
            toast.success(t('Card deleted'))
        }

        if (isErrorDeletion) {
            toast.error(t('Card could not be deleted'))
        }
        // eslint-disable-next-line
    }, [isErrorDeletion, isSuccessDeletion])

    const showDeleteCardTypes = (card: ICardType) => {
        setSelectedCard(card)
        setShowDeleteModal(true);
    }

    const showEditCardTypes = (card: ICardType) => {
        setSelectedCard(card)
        setShowEditModal(true);
    }

    const deleteCardTypes = () => {
        if (selectedCard) {
            deleteCardTypeMutation(selectedCard.id)
                .then(r => clearQueryCache())
        }
        else{
            console.error('no card selected')
        }
    }

    const resetAddForm = () => {
    }

    const copyLink = (inputElementId: string) => {
        const elInput: HTMLInputElement | null = document.getElementById(inputElementId) as HTMLInputElement
        if (elInput) {
            elInput.select()
            document.execCommand('copy')
            toast.success(t('URL copied'))
        }
    }

    return (
        <div className='mr-4'>
            <h1>{t('Cards to sell')}</h1>

            {!hasMollieSet && (
                <div className="mt-2 mb-2 alert alert-danger">
                    {t('Note that Mollie is not connected')}
                    <br/>
                    {t('Add the connection here - Configuration > Integrations')}
                </div>
            )}

            <div>
                <Button variant='primary' id='add-cardtype' onClick={() => setShowAddModal(true)} className='mt-3 mr-3'>
                    {t('Add new card')}
                </Button>

                <Button
                    id='view-order-page'
                    variant='outline-secondary'
                    onClick={() => window.open(`/order/${persistentStore.user?.sale_slug}`, '_blank')} className='mt-3'
                >
                    {t('View order page')}
                </Button>
            </div>

            <Row>
                <Col md={9}>
                    <CardTypeSettings/>

                    <Card className='mt-4 mb-5'>
                        <Card.Body>
                            {cardtypesQuery.isLoading && <Loader/>}

                            {cardtypesQuery.data?.card_types.map((card: ICardType, index: number) => {
                                return <CardType
                                    card={card}
                                    key={index}
                                    showDeleteCardTypes={() => showDeleteCardTypes(card)}
                                    showEditCardTypes={() => showEditCardTypes(card)}
                                />
                            })}

                            {cardtypesQuery.data?.card_types.length === 0 && !cardtypesQuery.isLoading && (
                                <Alert variant='info'>
                                    {t('No cards yet')}. {t('Add one using the button below')}.
                                </Alert>
                            )}
                        </Card.Body>
                    </Card>

                    {ordersQuery.data && ordersQuery.data.length > 0 && (
                        <Row className="block-row pt-3 pb-3 ml-1 mr-1">
                            <Col md={12}>
                                <h3>{t('Orders')}</h3>
                                <OrderTable teamId={persistentStore.user?.team_id}/>
                            </Col>
                        </Row>
                    )}
                </Col>

                <Col md={3}>
                    <Row className="block-row pt-1 pb-1 pl-1 pr-1 mt-4">
                        <Col md={12} className="pl-0 pr-0">
                            <Card>
                                <Card.Header style={{backgroundColor: "white"}}>
                                    <Card.Title className="mb-0">{t('Meta')}</Card.Title>
                                    <small>{t('Extra Options')}</small>
                                </Card.Header>
                                <Card.Body>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="price-in-cents">{t('Link to salespage')}</label>
                                        <div className='d-flex flex-row'>
                                            <input
                                                type="text"
                                                id="inputSalesPageLink"
                                                className="form-control"
                                                value={salesPageUrl}
                                                readOnly={true}
                                            />
                                            <Tooltip text={t('copy url')}>
                                                <Button
                                                    variant={'light'}
                                                    className='ml-1'
                                                    onClick={(event) => copyLink('inputSalesPageLink')}
                                                    data-for='url-training'
                                                    data-tip
                                                >
                                                    <FontAwesomeIcon icon={faCopy} className="material-icons"/>
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {showAddModal && (
                <AddCardTypeModal
                    shouldShow={showAddModal}
                    hide={() => setShowAddModal(false)}
                    submit={addCardType}
                    loading={loading}
                />
            )}

            {showEditModal && selectedCard && (
                <EditCardTypeModal
                    card={selectedCard}
                    shouldShow={showEditModal}
                    hide={() => setShowEditModal(false)}
                    loading={loading}
                />
            )}

            {showDeleteModal && selectedCard && (
                <SweetAlert
                    warning
                    showCancel
                    allowEscape={true}
                    closeOnClickOutside={true}
                    confirmBtnText={t("Yes, remove the card type")}
                    cancelBtnText={t("Cancel")}
                    confirmBtnBsStyle="danger"
                    title={t('Are you sure?')}
                    onConfirm={() => deleteCardTypes()}
                    onCancel={() => setShowDeleteModal(false)}
                    focusCancelBtn
                >
                    <p>{t('You cannot undo the removal of a card type!')}</p>
                </SweetAlert>
            )}

        </div>
    )
}

export default CardTypesPage;
