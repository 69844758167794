import React, {useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {units} from '../../api/units'
import {toast} from 'react-toastify'
import './UnitAdder.scss'
import './UnitEdit.scss'
import Spinner from "react-bootstrap/Spinner";
import {Alert, Col, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import store from "../../stores/store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import {stripnameTimePlural, stripnameTimeSingular} from "../../utils/stripname";
import {ICustomer} from "../../interfaces/ICustomer";
import persistentStore from "../../stores/persistentStore";
import {INewUnit, IUnit} from "../../interfaces/IUnit";
import {useQuery} from 'react-query';
import { useParams } from "react-router-dom";
import {useHistory} from "../../utils/router";
import {transformHoursIntoMinutes} from "../../utils/helpers"
import {customers as customersApi} from '../../api/customers'
import {ICard} from '../../interfaces/ICard'
import UseTimeContext from "../../contexts/UseTimeContext";

const UnitEdit = () => {
    const history = useHistory()
    const { customerId, unitid }: any = useParams();
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState<any>(1)
    const [notifyByEmail, setNotifyByEmail] = useState(persistentStore.user?.settings.unitAdder.mailByDefault || false)
    const [description, setDescription] = useState('')
    const [creationDate, setCreationDate] = useState<any>(new Date())
    const [cardToUse, setCardToUse] = useState<number>()
    const [error, setError] = useState<string>('')
    const [customer, setCustomer] = useState<ICustomer>()
    const [usedCard, setUsedCard] = useState<ICard>()
    const [unit, setUnit] = useState<IUnit>()
    const {t} = useTranslation()
    const useTime = useContext(UseTimeContext)

    const unitQuery = useQuery(
        'getUnitById',
        () => units.get(unitid)
    );

    const customerQuery = useQuery(
        ['getCustomer', customerId],
        () => customersApi.getByPublicId(customerId)
    )

    useEffect(() => {
        if (useTime) {
            setAmount('01:00')
        }
    }, [useTime])

    useEffect(() => {
        if(unitQuery.data){
            let receivedUnit = unitQuery.data.units
            setUnit(receivedUnit)
        }
    }, [unitQuery])

    useEffect(() => {
        if(unit){
            useTime ? setAmount(unit.amount.time) : setAmount(unit.amount.strips)
            setNotifyByEmail(unit.notify_by_email)
            setDescription(unit.description)
            setCardToUse(unit.card.id)
            if(unit?.created_at !== ''){
                setCreationDate(new Date(unit?.created_at))
            }
        }
    }, [unit, useTime])

    // if we received customer via props, use that
    // otherwise, fetch the customer ourselves
    useEffect(() => {
        if (customerQuery?.data ) {
            setCustomer(customerQuery.data)
            if(unit){
                const usedCards = customerQuery.data.cards.filter(function(card:any){
                    return (card.id === unit.card.id)
                });
                setUsedCard(usedCards[0])
            }
        }
    }, [customerQuery, unit])

    const isValidAmount = (amount: number) => {

        // must be numeric
        if (isNaN(amount)) {
            return false
        }

        return amount >= 1
    }

    /**
     * Empty the error message and make sure the error-div is not hidden
     * anymore
     */
    const resetError = () => {
        setError('')
    }

    // The function which is called when submitting a work-entry ( aka updating
    // a unit to a card) when the form is submitted
    const editUnit = (event: any) => {
        event.preventDefault()
        store.triggerEvent('unit_edited')

        resetError()

        if (persistentStore.user?.settings.useType !== 'hours') {
            // only do something when a valid value has been provided
            if (!isValidAmount(amount)) {
                setError('Geef 1 of meer strippen op.')
                return
            }
        }

        if (customerQuery?.data === undefined) {
            setError(t('Customer is not defined, cannot add a unit'))
            return
        }

        if(!useTime && usedCard && (amount -  unitQuery.data.units.amount.strips) > usedCard?.stats.strips_available){
            setError('Niet genoeg strippen beschikbaar')
            return
        }

        if(useTime && usedCard && ((transformHoursIntoMinutes(amount)*60) - (unitQuery.data.units.amount.strips*3600)) > usedCard?.stats.time_in_seconds_available  ){
            setError('Niet genoeg tijd beschikbaar')
            return
        }

        const unit: INewUnit = {
            amount: amount,
            description: description,
            customer_public_id: `${customer?.public_id}`,
            notify_by_email: notifyByEmail,
            card_to_use: cardToUse,
            date: creationDate,
        }

        sendUnitToAPi(unit)
    }

    const sendUnitToAPi = (unit: INewUnit) => {
        setLoading(true)

        units.update(unitid, unit)
            .then((response) => {
                setLoading(false)

                // show different message when a recurrence was added
                if(response.units.length > 0 && response.units[0].recurrence_id > 0){
                    toast.success('Herhaalde afboeking toegevoegd')
                }
                else {
                    if (persistentStore.user?.settings.useType === 'hours') {
                        toast.success('tijd afgeboekt')
                    } else {
                        const name = unit.amount.strips === 1 ? stripnameTimeSingular() : stripnameTimePlural()
                        toast.success(name + ' afgeboekt')
                    }
                }
                history.push('/customers/'+customerId)
                // reset the form
                resetForm()

                // update the customer in the store with information from the server
            })
            .catch((error) => {
                setLoading(false)
                if (!error.response) {
                    console.error(error)
                    return
                }
                if (error.response.data.errors) {
                    toast.error(error.response.data.errors.detail)
                }
            })
    }

    const resetForm = () => {
        setDescription('')
        setAmount(1)
    }

    return (
        <>
            <h1>{t('Edit unit')}</h1>

            <Row id="UnitEdit" className={`UnitEdit block-row unit-adder ml-0 col-sm-12`}>
                <Col sm={12} className="info-block">
                    <form>
                        <div className="form-row">
                            <Col md={3} className="form-group">
                                {useTime ? (
                                    <>
                                        <label htmlFor="amountUnits" className="text-left">
                                            Tijd
                                        </label>
                                        <input type="time"
                                               className="form-control"
                                               onChange={(e) => {
                                                   setAmount(e.target.value)
                                               }}
                                               value={amount}
                                               disabled={loading || unitQuery?.isLoading}
                                        />
                                    </>

                                ) : (
                                    <>
                                        <label htmlFor="amountUnits"
                                               className="text-left">Aantal {useTime ? 'strippen' : stripnameTimePlural()}</label>
                                        <select
                                            className="form-control col-10"
                                            id="amountUnits"
                                            onChange={(e) => {
                                                setAmount(parseInt(e.target.value, 10))
                                            }}
                                            value={amount}
                                            disabled={loading || unitQuery?.isLoading}
                                        >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </>
                                )}
                            </Col>
                        </div>

                        <div className="form-row">
                            <Col md={12} className="form-group">
                                <label htmlFor="inputDescription">{t('Description')}</label>
                                <input type="textarea"
                                       className="form-control"
                                       id="inputDescription"
                                       onChange={(e) => setDescription(e.target.value)}
                                       placeholder="Bijv: boekhouding bijgewerkt"
                                       value={description}
                                />
                            </Col>
                        </div>

                        <div className="form-row">
                            <Col md={12} className="form-group">
                                <label htmlFor="inlineFormCreationDate">{t('Creation date')}</label>
                                <DatePicker
                                    selected={creationDate}
                                    onChange={date => setCreationDate(date)}
                                    dateFormat="dd-MM-yyyy, H:mm"
                                    className="form-control"
                                    showTimeSelect
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Creation date"
                                    locale="nl"
                                />
                            </Col>

                            <Col md={12} className="form-group">
                                <Button
                                    id='decrease-strips'
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={editUnit}
                                    disabled={loading || unitQuery?.isLoading}
                                >
                                    {(loading || unitQuery?.isLoading) &&
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                                    {(!loading && !unitQuery?.isLoading) && t('Save Changes')}
                                </Button>

                                <Button variant="link" className="btn ml-2" onClick={() => history.push('/customers/'+customerId)}>
                                    {t('Cancel')}
                                 </Button>

                            </Col>
                        </div>
                    </form>

                    {error && <Alert variant='danger'>{error}</Alert>}
                </Col>
            </Row>
        </>
    )
}

export default observer(UnitEdit)
