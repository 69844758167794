import persistentStore from "../../stores/persistentStore";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {ICustomer} from "../../interfaces/ICustomer";
import {QueryResult} from "react-query";
import './CustomersDataTable.scss'
import {secondsToTime} from "../../utils/helpers";
import FilterComponent from "../../components/FilterComponent";

type CustomersDataTableProps = {
    customers: QueryResult<any>,
    addCustomer: (customerPublicId: string) => void,
    removeCustomer: (customerPublicId: string) => void,
    selectedCustomers: string[],
    amountSelected: number | string,
    useTime: boolean,
    loading: boolean,
    updateCustomerAmount: (customerPublicId: any, selectedAmount: any) => void
    customerQuantities: any,
    conditionalRowStyles: any,
}

const CustomersDataTable = (props: CustomersDataTableProps) => {
    const {t} = useTranslation()
    const [secondsSelected, setSecondsSelected] = useState<number>(0)
    const {customers, selectedCustomers, addCustomer, removeCustomer, amountSelected, useTime, updateCustomerAmount, customerQuantities, conditionalRowStyles} = props
    const [filterText, setFilterText] = React.useState<string>('');
    const [filteredCustomers, setFilteredCustomers] = useState<ICustomer[]>([])
    const initialAmount = useTime ? '01:00' : 1

    useEffect(() => {
        if (!customers.data) {
            return
        }

        const customersFilteredByQuery = customers.data.filter((customer: ICustomer) => {
            // filter out archived customers
            if (customer.archived) {
                return false
            }

            if (filterText === '') {
                return true
            }
            const data = JSON.stringify(customer).toLowerCase()
            const query = filterText.toLowerCase()

            return data && data.includes(query)
        })
        setFilteredCustomers(customersFilteredByQuery)
    }, [customers.data, filterText, customerQuantities])

    // calculate the seconds to be booked off in order to see which customers have no sufficient time left
    useEffect(() => {
        if (useTime && typeof amountSelected === 'string') {
            const splitTimeToBeBookedOff = amountSelected.split(":")
            const hours: number = parseInt(splitTimeToBeBookedOff[0], 10)
            const minutes: number = parseInt(splitTimeToBeBookedOff[1], 10)
            const secondsToBeBookedOff: number = (hours * 3600) + (minutes * 60)

            setSecondsSelected(secondsToBeBookedOff)
        }
    }, [amountSelected, useTime, customers.data])

    // whenever the amount to be booked off changes, remove customers which have no sufficient credit left
    useEffect(() => {
        removeCustomersWithInsufficientCredit()
        // eslint-disable-next-line
    }, [amountSelected, customerQuantities])

    const removeCustomersWithInsufficientCredit = () => {
        customers.data?.forEach(
            (customer: ICustomer) => {
                const amountToBeBookedOff = useTime
                    ? secondsSelected
                    : customerQuantities[customer.public_id]

                const hasSufficientCredit = useTime
                    ? customer.stats.total_time_in_seconds_available >= amountToBeBookedOff
                    : customer.stats.total_strips_available >= amountToBeBookedOff

                if (!hasSufficientCredit) {
                    removeCustomer(customer.public_id)
                }
            }
        )
    }

    const customerHasSufficientStrips = (customer: ICustomer): boolean => {
        return useTime
            ? (customer.stats.total_time_in_seconds_available > 0 && secondsSelected <= customer.stats.total_time_in_seconds_available)
            : (customer.stats.total_strips_available > 0 && customerQuantities[customer.public_id] <= customer.stats.total_strips_available)
    }

    const CheckboxWithName = (props: { customer: ICustomer }) => {
        const customer: ICustomer = props.customer
        const hasSufficientStrips: boolean = customerHasSufficientStrips(customer)
        const attrIdVal = `id_${customer.public_id}`

        return <label className={!hasSufficientStrips ? 'insufficient-strips' : ''}>
            <input
                type="checkbox"
                checked={selectedCustomers.includes(customer.public_id) && hasSufficientStrips}
                disabled={!hasSufficientStrips}
                className={!hasSufficientStrips ? 'insufficient-strips' : ''}
                id={attrIdVal}
                onChange={(event) => {
                    event.target.checked
                        ? addCustomer(customer.public_id)
                        : removeCustomer(customer.public_id)
                }}
            />
            &nbsp;
            {props.customer.name}
        </label>
    }

    const AmountSelectBox = (props: { customer: ICustomer }) => {
        const customer: ICustomer = props.customer
        if (useTime) {
            return (
                <input type="time"
                       className="form-control"
                       onChange={(e) => {
                           updateCustomerAmount(customer.public_id, e.target.value)
                       }}
                       value={(!customerQuantities[customer.public_id] ? initialAmount : customerQuantities[customer.public_id])}
                />)
        }

        return (
            <select
                className="form-control col-10"
                id="customerAmountUnits"
                onChange={(e) => {
                    updateCustomerAmount(customer.public_id, parseInt(e.target.value, 10))
                }}
                value={customerQuantities[customer.public_id]}
            >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
            </select>
        )
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return <FilterComponent
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
        />;
    }, [filterText]);

    const columns = [
        {
            name: t('Customer name'),
            sortable: true,
            selector: 'name',
            cell: (customer: ICustomer) => <CheckboxWithName customer={customer}/>,
        },
        {
            name: `${useTime ? 'Tijd' : persistentStore.user?.settings?.stripName.plural} beschikbaar`,
            selector: 'stats.total_strips_available',
            sortable: true,
            cell: (customer: ICustomer) => {
                const className = customerHasSufficientStrips(customer) ? '' : 'insufficient-strips'
                const quantity = useTime ? secondsToTime(customer.stats.total_time_in_seconds_available) : customer.stats.total_strips_available

                return (
                    <div className={className}>
                        {quantity}
                    </div>
                )
            }
        },
        {
            name: t('amount'),
            sortable: false,
            cell: (customer: ICustomer) => <AmountSelectBox customer={customer}/>
        }
    ]

    const customerClicked = (customer: ICustomer) => {
        if (selectedCustomers.includes(customer.public_id)) {
            removeCustomer(customer.public_id)
            return
        }

        addCustomer(customer.public_id)
    }

    return (
        <DataTable
            className='customers-data-table'
            columns={columns}
            data={filteredCustomers}
            pagination={filteredCustomers.length > 15}
            paginationPerPage={persistentStore.pageLength}
            paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
            onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
            noDataComponent={<div>Er zijn geen klanten. Maak je eerste aan op de klantenpagina</div>}
            noHeader={true}
            progressComponent={<div><strong>...{t('Loading customers')}</strong></div>}
            progressPending={customers.isLoading}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            pointerOnHover={true}
            onRowClicked={(customer: ICustomer) => customerClicked(customer)}
            conditionalRowStyles={conditionalRowStyles()}
        />
    );
}

export default observer(CustomersDataTable)
