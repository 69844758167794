import React from 'react';
import {observer} from 'mobx-react'
import {Col} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import moment from 'moment';
import DataTable from "react-data-table-component";
import persistentStore from "../../stores/persistentStore";
import {ICustomer} from "../../interfaces/ICustomer";
import {mails} from "../../api/mails";
import {IMail} from "../../interfaces/IMail";

type CustomerMailsProps = {
    customer: ICustomer,
    title: string
}

const CustomerMails = (props: CustomerMailsProps) => {
    const {t} = useTranslation()
    const {customer, title} = props
    const mailsQuery = useQuery(
        ['mails', customer?.public_id || 0],
        () => mails.getByCustomerPublicId(customer.public_id)
    )

    // const handleSendAgain = (mail: IMail) => {
    //     customers.sendCustomerMails(mail.id, customer.public_id)
    //         .then((response) => {
    //             if (response.success) {
    //                 toast.success(t('Email sent'));
    //             }
    //         })
    // }

    const columns = [
        {
            name: t('Email'),
            selector: 'email',
            sortable: true,
            width: '250px'
        },
        {
            name: t('Subject'),
            selector: 'subject',
            sortable: true,
            grow: 2
        },
        {
            name: t('Sending date'),
            selector: 'sent_date',
            sortable: true,
            cell: (mail: IMail) => (moment(mail.sent_date).format('YYYY-MM-DD HH:mm')),
        },
        // {
        //     name: t('Action'),
        //     sortable: false,
        //     cell: (row: any) => <Button variant='outline-primary' onClick={() => handleSendAgain(row)}>{t('Send again')}</Button>,
        // },
    ]

    return (
            <Col lg={12} className="mt-5">
                <h2>{title}</h2>
                <DataTable
                    columns={columns}
                    data={mailsQuery.data}
                    pagination={(mailsQuery.data||[]).length > 15}
                    paginationPerPage={persistentStore.pageLength}
                    paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
                    onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
                    noHeader
                    subHeader={false}
                    persistTableHead
                    progressPending={mailsQuery.isLoading}
                    defaultSortField='sent_date'
                    defaultSortAsc={false}
                    noDataComponent='Er zijn geen records om weer te geven'
                />
            </Col>
    );
}

export default observer(CustomerMails);
