import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {observer} from 'mobx-react'
import {users} from '../../api/users'
import {useRouter} from '../../utils/router'
import {useLoading} from "../../utils/helpers";
import {useTranslation} from "react-i18next";
import Alert from "react-bootstrap/Alert";
import FormGroup from "react-bootstrap/FormGroup";
import Spinner from "react-bootstrap/Spinner";
import Header from "../../parts/Header";

const ResetPassword = () => {
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState<boolean>(false)
    const [mustRenew, setMustRenew] = useState<boolean>(false)
    const [loading, startLoading, stopLoading] = useLoading()
    const router = useRouter()
    const {t} = useTranslation()
    const {token, email} = router.query

    const handlePasswordChange = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (password.length < 8) {
            setError(t('Password must be at least 8 characters long'))
            return
        }

        startLoading()

        users.resetPassword({password: password, token: token, email: email})
            .then((response) => {

                if (!response.success) {
                    if(response.errors?.detail === 'passwords.token'){
                        setMustRenew(true)
                    }
                    else{
                        setError(response.errors.title)
                    }
                    return
                }

                setSuccess(true)
            })
            .catch(error => {
                let data = error.response.data
                if(data.errors?.detail === 'passwords.token'){
                    setMustRenew(true)
                    return
                }
                setError(data.errors?.title || t('An error occurred'))
            })
            .finally(() => {
                stopLoading()
            })
    }

    return (
        <>
            <Header />
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <form className="form-signin col-md-5" onSubmit={handlePasswordChange}>

                    <h1 className="h3 mb-3 font-weight-normal">{t('Change your password')}</h1>

                    {error && <Alert variant='danger'>{error}</Alert>}
                    {success && <Alert variant='success'>{t('Password successfully changed. Go to the Login page and login using your new password')}</Alert>}
                    {mustRenew && <Alert variant='warning'>Ongeldig token. Waarschijnlijk is de link verlopen. <a href={`${window.location.origin}/requestpasswordreset`}>Klik hier om een nieuwe link aan te vragen.</a></Alert>}
                    <FormGroup>
                        <label htmlFor="inputPassword" className="sr-only">{t('Password')}</label>
                        <input type="password" id="inputPassword" className="form-control" placeholder={t("Password")}
                               name="password" value={password} onChange={e => setPassword(e.target.value)}
                               disabled={success || loading}
                               minLength={8}
                               required
                        />
                    </FormGroup>

                    <button className="btn btn-primary btn-block" type="submit" disabled={success || loading}>
                        {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                        {! loading && t('Change password')}
                    </button>

                    <Link to="/login" className="btn btn-link">{t('Login')}</Link>
                </form>
            </main>
        </>
    )
}

export default observer(ResetPassword)
