import React from 'react';
import {IOrder} from "../../interfaces/IOrder";
import DataTable from "react-data-table-component";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import uiStore from "../../stores/uiStore";
import persistentStore from "../../stores/persistentStore";
import store from "../../stores/store";
import {Link} from "../../utils/router";
import { useQuery } from 'react-query';
import { orders } from '../../api/orders';
import { toast } from 'react-toastify';

const OrderTable = (props: any) => {
    const {teamId}: { teamId: number } = props
    const {t} = useTranslation()
    const orderRecords = useQuery(
        'orderRecords',
        () => orders.getFromTeam(teamId),
        { enabled: teamId !== undefined }
    )

    const columns = [
        {
            name: t('Status'),
            selector: 'status',
            sortable: true,
            width: '100px',
            cell: (order: IOrder) => {
                let type = 'info'
                let status = order.last_invoice?.payment?.status ?? 'onbekend'
                let text = status

                if (status === 'paid') {
                    type = 'success'
                    text = t('paid')
                }
                else if (status === 'expired') {
                    type = 'warning'
                    text = t('expired')
                }
                else if (status === 'canceled' || status === 'cancelled') {
                    type = 'warning'
                    text = 'geannuleerd'
                }
                else {
                    // no payment? then text = 'geen betaling'
                    if(!order.last_invoice){
                        text = 'geen factuur'
                    }
                    else if(!order.last_invoice?.payment){
                        text = 'geen betaling'
                    }

                    type = 'warning'
                }

                return (
                    <span
                        data-id={order.public_id}
                        className={`badge badge-pill badge-${type}`}
                        style={{padding: '7px', fontSize: '0.9rem'}}
                    >
                    {text}
                </span>
                )
            }
        },
        {
            width: '20px',
            sortable: false,
            cell: (order: IOrder) => {
                let status = order.last_invoice?.payment?.status ?? 'onbekend'
                if(status === 'open' && order.last_invoice?.payment?.checkout_url){
                    return (
                        <span
                            onClick={() => {
                                const copyToClipboard = (text: string) => {
                                    if (navigator.clipboard && navigator.clipboard.writeText) {
                                        navigator.clipboard.writeText(text)
                                            .then(() => toast.success(t('Link naar de betaalpagina is gekopieerd. Je kunt deze nu ergens plakken')))
                                            .catch(() => fallbackCopyToClipboard(text));
                                    } else {
                                        fallbackCopyToClipboard(text);
                                    }
                                };

                                const fallbackCopyToClipboard = (text: string) => {
                                    const textArea = document.createElement("textarea");
                                    textArea.value = text;
                                    document.body.appendChild(textArea);
                                    textArea.focus();
                                    textArea.select();
                                    try {
                                        document.execCommand('copy');
                                        toast.success(t('Link naar de betaalpagina is gekopieerd. Je kunt deze nu ergens plakken'));
                                    } catch (err) {
                                        toast.error(t('De link naar de betaalpagina is niet gekopieerd. Probeer het opnieuw.'));
                                    }
                                    document.body.removeChild(textArea);
                                };

                                copyToClipboard(order.last_invoice?.payment?.checkout_url || '');
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/img/ideal-logo.png`}
                                alt={t('Copy payment link')}
                                style={{ width: '20px', height: '20px' }}
                            />
                        </span>
                    )
                }

                return ''
            },
        },
        {
            name: t('Buyer'),
            sortable: true,
            cell: (order: IOrder) => {
                if (order.buyer.deleted_at === null) {
                    return <Link to={`/customers/${order.buyer.public_id}`}>{order.buyer.name}</Link>
                }

                return (
                    <label style={{display: 'flex', alignItems: 'center'}}>
                        {order.buyer.name}
                        <span
                            className='badge badge-pill badge-danger'
                            style={{marginLeft: '3px', padding: '7px', fontSize: '0.7rem'}}
                        >
                            {t('Deleted')}
                        </span>
                    </label>
                )
            }
        },
        {
            name: t('Price'),
            selector: 'order_price_in_cents',
            sortable: true,
            cell: (order: IOrder) => {
                return (order.order_price_in_cents / 100).toLocaleString(store.locale, uiStore.moneyOptions)
            },
        },
        {
            name: t('Invoice'),
            selector: 'invoice',
            sortable: true,
            cell: (order: IOrder) => {
                if (!order.last_invoice) {
                    return ''
                }

                return (
                    <a
                        rel="noopener noreferrer"
                        href={`/invoice/${order.last_invoice.public_id}`}
                        target='_blank'
                    >
                        {t('invoice')}
                    </a>
                )
            },
        },
        {
            name: t('Created'),
            selector: 'created_at',
            sortable: true,
            cell: (order: IOrder) => moment(order.created_at).format('YYYY-MM-DD'),
        },
    ]

    return (
        <div>
            <DataTable
                className='OrderListView order-list-view'
                columns={columns}
                progressPending={orderRecords.isLoading}
                data={orderRecords.data ?? []}
                pagination={orderRecords.data?.length > 15}
                paginationPerPage={persistentStore.pageLength}
                paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
                onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
                noHeader={true}
                noDataComponent={<div>{t('No orders')}</div>}
            />
        </div>
    )
}

export default OrderTable;
