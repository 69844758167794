import axios from 'axios'
import errorChecker from './errorchecker'
import persistentStore from "../stores/persistentStore";
import {ICompany} from "../interfaces/ICompany";

const teamRequests = () => {

    const addTeam = async (company: ICompany) => {
        const path = `/teams/add`
        const data = company
        const config = persistentStore.authHeader

        return axios.post(persistentStore.apiUrl + path, data, config)
            .then(
                (response) => {
                    if (response.request.status !== 400) {
                        return response
                    }

                    return JSON.parse(response.request.response)
                })
            .then(response => errorChecker(response)).then(response => {
                const data = response.data

                if (!data.data.company) {
                    console.log('The response did not contain the created company')
                    return response.data
                }
                return data.data
            })
    }

    const fetchCSRFToken = async () => {
        return await axios.get(
            persistentStore.apiUrl + '/sanctum/csrf-cookie',
            {
                withCredentials: true,
            }
        );
    }

    const addCustomerCompanyToInvoice = async (company: ICompany, invoicePublicId: string) => {
        const path = `/teams/add-customer-company-to-invoice/${invoicePublicId}`
        const data = company
        const config = persistentStore.authHeader

        await fetchCSRFToken()

        return axios.post(persistentStore.apiUrl + path, data, config)
            .then(
                (response) => {
                    if (response.request.status !== 400) {
                        return response
                    }

                    return JSON.parse(response.request.response)
                })
            .then(response => errorChecker(response)).then(response => {
                const data = response.data

                if (!data.data.company) {
                    console.log('The response did not contain the created company')
                    return response.data
                }
                return data.data
            })
    }

    const updateCompany = async (company: ICompany) => {
        const path = `/teams/${company.id}`
        const config = persistentStore.authHeader

        return axios.put(persistentStore.apiUrl + path, company, config)
            .then(response => errorChecker(response))
            .then(response => response.data)
    }

    const updateCustomerCompanyOnInvoice = async (company: ICompany, invoicePublicId: string) => {
        const path = `/teams/update-customer-company-on-invoice/${company.id}/${invoicePublicId}`
        const config = persistentStore.authHeader

        return axios.put(persistentStore.apiUrl + path, company, config)
            .then(response => errorChecker(response))
            .then(response => response.data)
    }

    const get = async (companyId: number) => {
        const path = `/teams/view/${companyId}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(
                response => errorChecker(response)).then(response => {
                return response.data
            })
    }

    const events = async (teamid: number) => {
        const path = `/events/of-team/${teamid}`
        const config = persistentStore.authHeader

        return axios.get(persistentStore.apiUrl + path, config)
            .then(response => errorChecker(response))
            .then(response => { return response.data.data})
    }

    return {
        addCompany: addTeam,
        addCustomerCompanyToInvoice,
        updateCompany,
        updateCustomerCompanyOnInvoice,
        get,
        events
    }
}

export const teams = teamRequests()
