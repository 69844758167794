import React, {useEffect, useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ISubscription} from '../../interfaces/ISubscription';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

type SubscriptionEditModalType = {
    shouldShow: boolean,
    hide: () => void,
    updateSubscription: (updatedStatus: any) => void,
    loading: boolean,
    subscription: ISubscription
}

const SubscriptionEditModal = (props: SubscriptionEditModalType) => {
    const {t} = useTranslation()
    const {subscription} = props;
    const [status, setStatus] = useState<string>(props.subscription.status)
    const [endDate, setEndDate] = useState<any>(new Date())
    const handleSubmit = (event: any) => {
        event.preventDefault()

        // just keep the following properties: id, end_date, status
        const updatedStatus: any = {
            id: props.subscription.id,
            end_date: moment(endDate).format('Y-M-D'),
            status: status
        }

        props.updateSubscription(updatedStatus)
    }

    useEffect(() => {
        setStatus(subscription.status)
        setEndDate(new Date(moment(subscription.end_date).format('Y-M-D HH:mm')))
    }, [subscription])

    return (
        <Modal id='edit-cardtype' show={props.shouldShow} onHide={props.hide}>
            <form onSubmit={(event: any) => handleSubmit(event)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Edit subscription')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="inputStatus">{t('Status of Subscription')}</label>
                        <select
                            className="form-control"
                            id="inputStatus"
                            placeholder={t('Status of the subscritpion')}
                            name="status"
                            value={status}
                            onChange={(event: any) => setStatus(event.target.value)}
                            required
                        >
                            <option value='paid'>{t('Paid')}</option>
                            <option value='failed'>{t('Failed')}</option>
                            <option value='cancelled'>{t('Cancelled')}</option>
                        </select>
                        <small></small>
                    </div>

                    <div className="form-group">
                        <label htmlFor='inputAccessUntil'>{t('Access Until')}</label>
                        <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            dateFormat="dd-MM-yyyy, H:mm"
                            className="form-control"
                            showTimeSelect
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Access Until"
                            locale="nl"
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="link" id='cancel-edit' className="text-danger" onClick={() => props.hide()}>
                        {t('Cancel')}
                    </Button>

                    <Button
                        type="submit"
                        size='lg'
                        id='submit-subscription-edits'
                        variant="primary"
                        className="mr-1"
                        disabled={props.loading}
                    >
                        {props.loading && (
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                        )}
                        {!props.loading && t('Save')}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default SubscriptionEditModal;
