import React, {useState} from 'react';
import DataTable from "react-data-table-component";
import {useTranslation} from "react-i18next";
import FilterComponent from "../../components/FilterComponent";
import moment from "moment/moment";
import {ISubscription} from "../../interfaces/ISubscription";
import SubscriptionEditModal from "./SubscriptionEditModal";
import persistentStore from "../../stores/persistentStore";
import {toast} from 'react-toastify';
import {subscriptions, subscriptions as subscriptionsApi} from '../../api/subscriptions';
import './SubscriptionTable.scss'
import {Edit2, FileText, ShoppingCart} from "react-feather";
import {useQuery} from "react-query";

const SubscriptionTable = (props: any) => {
    const {t} = useTranslation()
    const [filterText, setFilterText] = React.useState<string>('');
    const {teamId} = props
    const [showModal, setShowModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [editedSubscription, setEditedSubscription] = useState<ISubscription>({} as ISubscription)
    const subscriptionPayments = useQuery(
        'userSubscriptionPayments',
        () => subscriptions.ofTeam(teamId),
        {
            enabled: teamId !== undefined
        }
    )

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return <FilterComponent
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
        />;
    }, [filterText]);

    const columns = [
        {
            name: 'Factuur',
            selector: 'invoice_number',
            sortable: true,
            width: '120px',
        },
        {
            name: t('Status'),
            selector: 'status',
            sortable: true,
            width: '100px',
        },
        {
            name: t('Type'),
            selector: 'type',
            sortable: true,
            width: '120px',
        },
        {
            name: t('Price'),
            selector: 'paid_amount_in_cents',
            sortable: true,
            width: '80px',
            cell: (subscription: ISubscription) => parseFloat((subscription.paid_amount_in_cents / 100).toString()),
        },
        {
            name: t('Start date'),
            selector: 'start_date',
            sortable: true,
            width: '120px',
            cell: (subscription: ISubscription) => moment(subscription.start_date).format('YYYY-MM-DD'),
        },
        {
            name: t('Access until'),
            selector: 'end_date',
            sortable: true,
            width: '190px',
            cell: (subscription: ISubscription) => {
                let daysLeft = moment(subscription.end_date).diff(moment(), 'days');
                if (daysLeft < 0) {
                    daysLeft = 0;
                }
                return `${moment(subscription.end_date).format('YYYY-MM-DD')} (${daysLeft} dagen)`;
            },
        },
        {
            name: t('Action'),
            selector: 'action',
            sortable: true,
            ignoreRowClick: true,
            cell: (subscription: ISubscription) => (
                <>
                    <Edit2
                        onClick={() => handleEditSubscription(subscription)}
                        className='edit-icon'
                    />

                    {subscription.plugandpay_order_id && (
                        <ShoppingCart
                            onClick={() => handleViewOrder(subscription)}
                            className='view-icon'
                        />
                    )}

                    {subscription.contract_id && (
                        <FileText
                            onClick={() => handleViewContract(subscription)}
                            className='view-icon'
                        />
                    )}
                </>
            ),
        }
    ]

    const handleViewOrder = (subscription: ISubscription) => {
        window.open(`https://admin.plugandpay.com/orders/${subscription.public_id}`, '_blank');
    }

    const handleViewContract = (subscription: ISubscription) => {
        window.open(`https://admin.plugandpay.com/subscriptions/${subscription.contract_id}`, '_blank');
    }

    const handleEditSubscription = (subscription: ISubscription) => {
        setEditedSubscription(subscription)
        setShowModal(true)
    }

    const handleUpdateSubscription = (subscription: ISubscription) => {
        setLoading(true)
        subscriptionsApi
            .update(subscription)
            .then((response: any) => {
                setLoading(false)

                // reload the subscription payments
                subscriptionPayments.refetch()

                if (response.success === true) {
                    toast.success(t('subscription info updated'))
                    setShowModal(false)
                }
            })
            .catch((error: any) => {
                console.log(`error caught when updating subscription: `, error)
            })
    }

    const filteredSubscriptions = (subscriptionPayments.data?.subscription_payments || [])
        .filter((subscription: ISubscription) => {

            if (filterText === '') {
                return true
            }
            const data = JSON.stringify(subscription).toLowerCase()
            const query = filterText.toLowerCase()

            return data && data.includes(query)
        });

    return (
        <>
            <DataTable
                className='OrderListView order-list-view'
                columns={columns}
                data={filteredSubscriptions}
                progressPending={subscriptionPayments.isLoading}
                pagination
                paginationPerPage={persistentStore.pageLength}
                paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
                onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
                noHeader={true}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                defaultSortField='created'
                defaultSortAsc={false}
            />

            <SubscriptionEditModal
                shouldShow={showModal}
                hide={() => setShowModal(false)}
                updateSubscription={handleUpdateSubscription}
                loading={loading}
                subscription={editedSubscription}
            />
        </>
    )
}

export default SubscriptionTable;
