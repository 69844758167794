import React from 'react';
import DataTable from "react-data-table-component";
import ProgressBar from "./ProgressBar";
import {useTranslation} from "react-i18next";
import {useRouter} from "../../utils/router";
import './CustomerListView.scss'
import FilterComponent from "../../components/FilterComponent";
import persistentStore from "../../stores/persistentStore";
import {ICustomer} from "../../interfaces/ICustomer";
import {pathToLogoDirectory, secondsToTime} from "../../utils/helpers";

type CustomerListViewProps = {
    customers: ICustomer[],
    loading: boolean,
    addCustomer: (customerPublicId: string) => void,
    removeCustomer: (customerPublicId: string) => void,
    selectedCustomers: string[],
    groupClicked: boolean
}

const CustomerListView = (props: CustomerListViewProps) => {
    const [filterText, setFilterText] = React.useState('');
    const {addCustomer, removeCustomer, selectedCustomers, customers, groupClicked, loading} = props
    const {t} = useTranslation()
    const router = useRouter()

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return <FilterComponent
            id='customer-search'
            onFilter={(e: any) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
        />;
    }, [filterText]);

    const shouldHide = (customer: ICustomer) => {
        if(groupClicked && ! selectedCustomers.includes(customer.public_id)){
            return true
        }

        return false
    }

    const filteredCustomers = customers
        ? customers.filter((customer: ICustomer) => {
            if (filterText === '') {
                return shouldHide(customer) ? false : true
            }
            const data = JSON.stringify(customer).toLowerCase()
            const query = filterText.toLowerCase()

            // hide when a group is clicked and this customer is not a member of that group
            return shouldHide(customer)
                ? false
                : data && data.includes(query)
        })
        : []

    const Checkbox = (checkboxProps: { customer: ICustomer }) => {
        const customer: ICustomer = checkboxProps.customer
        const attrIdVal = `id_${customer.public_id}`
        const isSelected = selectedCustomers.includes(customer.public_id)

        return <label className='d-flex flex-row'>
            <input
                type="checkbox"
                checked={isSelected}
                className='mr-2'
                id={attrIdVal}
                onChange={(event) => {
                    event.target.checked
                        ? addCustomer(customer.public_id)
                        : removeCustomer(customer.public_id)
                }}
            />
        </label>
    }

    const useTime = (persistentStore.user?.settings.useType === 'hours')
    const columns = [
        {
            name: '',
            sortable: false,
            width: '20px',
            cell: (customer: ICustomer) => <Checkbox customer={customer}/>
        },
        {
            name: '',
            selector: 'logo',
            sortable: false,
            width: '25px',
            cell: (customer: ICustomer) => customer.logo && <img width={25} src={`${pathToLogoDirectory()}/${customer.logo}`} alt={customer.name} className='customer-image rounded-circle' />
        },
        {
            name: t('Customer name'),
            selector: 'name',
            sortable: true,
            // cell: (customer: ICustomer) => <CheckboxWithName customer={customer}/>
        },
        {
            name: `${persistentStore.user?.settings?.stripName.plural} beschikbaar`,
            selector: 'stats.total_strips_available',
            sortable: true,
            cell: (customer: ICustomer) => {
                return useTime
                    ? secondsToTime(customer.stats.total_time_in_seconds_available)
                    : customer.stats.total_strips_available
            }

        },
        {
            name: t('Usage'),
            sortable: false,
            cell: (customer: ICustomer) => (
                <ProgressBar
                    customer={customer}
                />
            ),
        },
    ]

    const onRowClicked = (customer: ICustomer) => {
        router.push(`/customers/${customer.public_id}`)
    }

    const conditionalRowStyles = () => {
        return [{
            when: (customer: ICustomer) => {
                const isSelectElement: boolean = selectedCustomers.includes(customer.public_id)
                return isSelectElement
            },
            style: {
                backgroundColor: 'rgb(233 253 226 / 1)',
            }
        }]
    }

    return (
        <div className='flex-fill'>
            <DataTable
                className='CustomerListView customer-list-view'
                columns={columns}
                conditionalRowStyles={conditionalRowStyles()}
                data={filteredCustomers}
                pagination={filteredCustomers.length > 15}
                paginationPerPage={persistentStore.pageLength}
                paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
                onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
                noHeader={true}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                onRowClicked={(event) => onRowClicked(event)}
                noDataComponent={<div>Er zijn geen klanten. Maak je eerste aan door op het plus-icoontje naast de titel te
                    klikken</div>}
                progressPending={loading}
                defaultSortField={'name'}
            />
        </div>
    )
}

export default CustomerListView;
