import React from "react";
import {Row, Col, Jumbotron, Button} from 'react-bootstrap'
import {Mail, CheckCircle, Eye} from 'react-feather';
import moment from "moment";
import {useTranslation} from "react-i18next";
import OrderTable from "./OrderTable";
import EventsTable from "./EventsTable";
import {users as usersApi} from '../../api/users';
import {useRouter} from "../../utils/router";
import {useQuery} from "react-query";
import SubscriptionTable from "./SubscriptionTable";

const UsersDetailsPage = () => {
    const {t} = useTranslation()
    const router = useRouter()

    const user = useQuery('getUser', () => usersApi.getOne(router.query.id))

    const impersonate = () => {
        if (user.data?.id === undefined) {
            console.error('No id known for user. Cannot impersonate')
            return
        }

        usersApi.impersonate(user.data?.id)
            .then(() => {
                router.push('/')
            })
    }

    return (
        <div id='users-list'>
            <Row className='col-12'>
                <h2>
                    {user.data?.name}
                    <Button variant="secondary" className='ml-1' onClick={() => impersonate()}>
                        {t('Impersonate')}
                    </Button>
                </h2>
            </Row>
            <Row className='col-12'>
                <Col md={12} className='p-0'>
                    <Jumbotron>
                        <Row>
                            <Col md={4}>
                                <h5 title="Email">
                                    <Mail className='mr-2'/>
                                    {user.data?.email}
                                </h5>
                            </Col>

                            <Col md={4}>
                                <h5 title="Customer type" className="text-capitalize">
                                    <CheckCircle className='mr-2'/>
                                    {user.data?.type}
                                    {user.data?.type === 'trial' && (
                                        <div>
                                            {moment().diff(user.data?.created_at, 'days')}&nbsp;{t('days')}
                                        </div>
                                    )}
                                </h5>
                            </Col>

                            <Col md={4}>
                                <h5 title="Last seen">
                                    <Eye className='mr-2'/>
                                    {moment(user.data?.last_seen || user.data?.created_at).fromNow(true)}
                                    &nbsp;{t('ago')} ({moment(user.data?.last_seen || user.data?.created_at).format('DD-MM-YYYY')})
                                </h5>
                            </Col>
                        </Row>
                    </Jumbotron>
                </Col>
            </Row>

            <Row className='col-12'>
                <Col md={12} className='p-0 pr-3'>
                    <h3>{t('Subscriptions')}</h3>
                    <SubscriptionTable teamId={user.data?.team_id}/>
                </Col>
            </Row>

            <Row className='col-12'>
                <Col md={12} className='p-0 pr-3'>
                    <h3>{t('Events')}</h3>
                    <EventsTable teamId={user.data?.team_id} />
                </Col>
            </Row>

            <Row className='col-12'>
                <Col md={12} className='p-0 pr-3'>
                    <h3>{t('Orders')}</h3>
                    <OrderTable teamId={user.data?.team_id} />
                </Col>
            </Row>
        </div>
    );
}

export default UsersDetailsPage;
