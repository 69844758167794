import momentjs from 'moment';
import 'moment/locale/nl';
import {useState} from "react";
import persistentStore from "../stores/persistentStore";

export const getCurrentTimeStamp = () => Math.round((new Date()).getTime() / 1000);

export const useLoading = (defaultValue: boolean = false) => {
    const [loading, setLoading ] = useState(defaultValue)

    const startLoading: (() => void) = () => setLoading(true)
    const stopLoading: (() => void) = () => setLoading(false)

    return [
        loading,
        startLoading,
        stopLoading
    ] as const
}

export const useSettingsLoading = (defaultValue=false) => {
    const [settingsLoading, setSettingsLoading ] = useState(defaultValue)

    const startSettingsLoading = () => setSettingsLoading(true)
    const stopSettingsLoading = () => setSettingsLoading(false)

    return [
        settingsLoading,
        startSettingsLoading,
        stopSettingsLoading
    ] as const
}

/**
 * Go from minutes to hours
 *
 * so, convert:
 * - 90 minutes into 01:30
 * - 60 minutes into 01:00
 * - 75 minutes into 01:15
 * @param totalMinutes  The number of minutes that needs to be transformed into an hour-notation
 */
export const transformMinutesIntoHours = (totalMinutes: number) => {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes/60);

    // add prefix zero's where needed
    const displayMinutes = minutes < 10 ? "0"+minutes : minutes;
    const displayHours = hours < 10 ? "0"+hours : hours;

    return displayHours+":"+displayMinutes;
};

// get the URL-part after the domainname ( i call it 'slug')
export const getSlug = () => {
    const url = window.location.href;

    const slugPattern = new RegExp('.*//.*?[^/](/.*)');
    const slug = slugPattern.exec(url);

    if(slug !== null){
        return slug[1];
    }
    else {
        return '/';
    }
}

/**
 * Set a locale for moment() globally
 *
 * @param input
 * @return {moment.Moment}
 */
export const moment = (input: any) => {
    momentjs.locale('nl');
    return momentjs(input);
};

/**
 * Go from hours to minutes
 *
 * so, convert:
 * - 01:30 into 90
 * - 01:00 into 60
 * - 01:15 into 75
 * @param stringHours  The number of minutes that needs to be transformed into an hour-notation
 */
export const transformHoursIntoMinutes = (stringHours: string) => {
    const regexTimeFormat = /\d{1,2}:\d\d/;
    const valuePassedValidation = stringHours.match(regexTimeFormat);

    if(valuePassedValidation === null){
        return 0;
    }

    const hours = parseInt(stringHours.split(':')[0], 10);
    const minutes = parseInt(stringHours.split(':')[1], 10)
    return hours * 60 + minutes;
};

export const secondsToTime = (seconds: number) => {
    const hours: number = parseInt((seconds / 3600).toString(), 10)
    const minutes: number = parseInt(((seconds % 3600) / 60).toString(), 10)
    const hourString: string = hours < 10
        ? `0${hours}`
        : hours.toString()
    let minuteString: string = minutes < 10
        ? `0${minutes}`
        : minutes.toString()

    return `${hourString}:${minuteString}`
}

export const serverBaseUrl = () => {
    return persistentStore.apiUrl.replace(/\/api\/v.*$/, '');
}

export const pathToLogoDirectory = () => {
    return persistentStore.apiUrl.replace(/\/api\/v.*$/, '') + '/assets/images/logo';
}

// export const fallbackCopyToClipboard = (text: string) => {
//     const textArea = document.createElement("textarea");
//     textArea.value = text;
//     document.body.appendChild(textArea);
//     textArea.focus();
//     textArea.select();
//     try {
//         document.execCommand('copy');
//         toast.success(t('Payment link copied to clipboard'));
//     } catch (err) {
//         toast.error(t('Failed to copy payment link'));
//     }
//     document.body.removeChild(textArea);
// };

// export const copyToClipboard = (text: string) => {
//     if (navigator.clipboard && navigator.clipboard.writeText) {
//         navigator.clipboard.writeText(text)
//             .then(() => toast.success(t('Payment link copied to clipboard')))
//             .catch(() => fallbackCopyToClipboard(text));
//     } else {
//         fallbackCopyToClipboard(text);
//     }
// }

