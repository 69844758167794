import Spinner from 'react-feather/dist/icons/settings';
import React, {useEffect, useState} from 'react'
import {Row, Col, Card} from "react-bootstrap";
import {User as UserIcon} from 'react-feather';
import {ICustomer} from "../../interfaces/ICustomer";
import {CustomerProgressBar} from "./CustomerProgressBar";
import {useTranslation} from "react-i18next";
import {useRouter} from "../../utils/router";
import {useTime} from "../../utils/useTime";
import {secondsToTime} from "../../utils/helpers";
import './CustomerRow.scss'

type Props = {
    customers: ICustomer[] | undefined,
    loading: boolean,
    title: string
}

export const CustomerOutOfStrips = (props: Props) => {
    const {customers, loading, title} = props
    const [sortedCustomers, setSortedCustomers] = useState(customers)

    // sort DESC by strips_available
    useEffect(() => {
        if (!customers) {
            return
        }

        const sortedDescRemaining: ICustomer[] = customers
            // remove archived customers
            .filter((customer: ICustomer) => customer.deleted_at === null)
            .filter((customer: ICustomer) => customer.archived === false)
            .sort((a: ICustomer, b: ICustomer) => {
                return a.stats.total_strips_available > b.stats.total_strips_available
                    ? 1
                    : b.stats.total_strips_available > a.stats.total_strips_available ? -1 : 0
            })

        // return top 5
        setSortedCustomers(sortedDescRemaining.slice(0,5))
    }, [customers])

    return (
        <Card>
            <Card.Header as="h5">{title}</Card.Header>
            <CustomerRows customers={sortedCustomers || []} />
            {loading && <h1 className="text-center"><Spinner className="spinner"/></h1>}
        </Card>
    )
}

const CustomerRows = ({customers}: {customers:ICustomer[]}) => {
    const router = useRouter()
    const {t} = useTranslation()

    return (
        <Card.Body>
            {customers.map((customer: ICustomer, index: number) => (
                <Row
                    className="border-bottom customer-row"
                    key={index} onClick={() => router.push(`/customers/${customer.public_id}`)}
                >
                    <Col md={8} className='pr-0'>
                        <Row>
                            <Col md={3} className="pr-0 pl-0 text-center">
                                <UserIcon size={50}/>
                            </Col>
                            <Col md={9}>
                                <b>{customer.name}</b>
                                <p>{(useTime ? secondsToTime(customer.stats.total_time_in_seconds_available) : customer.stats.total_strips_available) + ' ' + t('left')}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} className="pr-0 pt-2 pb-2">
                        <CustomerProgressBar customer={customer}/>
                    </Col>
                </Row>
            ))}
        </Card.Body>
    )
}
